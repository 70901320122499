import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/default-page-layout.js";
import PageTitle from '../components/PageTitle';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle mdxType="PageTitle">Izvedeni projekti</PageTitle>
    <h1>{`DOLB Cerkvenjak`}</h1>
    <h2>{`DOLB Cerkvenjak je bil prvi projekt Sončne zadruge.`}</h2>
    <p>{`V OŠ Cerkvenjak so se leta 2013 pričeli ukvarjali s pripravo na prenovo zelo zastarele kotlovnice na kurilno olje, ki je bila neučinkovita ter neekološka. Sama prenova je bila ocenjena na slabih 100.000 EUR. Ker je to za majhno občino predstavljalo precejšen zalogaj, so iskali možnosti, kako bi projekt izvedli na način, ki občine finančno ne bi obremenjeval. Sončna zadruga je tako rešitev ponudila in prišlo je do sodelovanja.`}</p>
    <p>{`Prva faza projekta prenove sistema ogrevanja je vključevala odstranitev obeh kotlov na kurilno olje z vso pripadajočo opremo iz kotlovnice v OŠ, vključno z rezervoarjem za EL-KO, obstoječim bojlerjem za STV, ekspanzijsko posodo in vso ostalo pripadajočo opremo.`}</p>
    <p>{`Namesto stare opreme se je nato vgradil nov kotel na lesno biomaso (sekanci) moči 250kW proizvajalca Fröling. Poleg tega se je v kotlovnico namestil še hranilnik toplote volumna 5.000 litrov, bojler za STV volumna 1500 litrov z ustrezno ekspanzijsko posodo, ustrezna ekspanzijska posoda za ogrevalno vodo, razdelilnik z vsemi potrebnimi črpalkami, ventili, manometri, termometri, itd.`}</p>
    <p>{`Prostor, kjer se je pred tem nahajal rezervoar za EL-KO, se je po odstranitvi le tega predelal v skladišče za sekance velikosti 35 m3.`}</p>
    <p>{`Na prenovljeno kotlovnico se je z novim cevnim razvodom preko toplotnih posta priključilo še občinsko stavbo in kulturni dom.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "initial",
          "marginRight": "initial",
          "maxWidth": "300px",
          "marginBottom": "3rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.999999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHXuEkF/8QAGBAAAgMAAAAAAAAAAAAAAAAAAxAREyH/2gAIAQEAAQUCrgmr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAECExQf/aAAgBAQAGPwIsGOeTdf/EABoQAAICAwAAAAAAAAAAAAAAAAABEUEhMfH/2gAIAQEAAT8hTodahHIWsn//2gAMAwEAAgADAAAAEIwP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QAJ//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBVn//EABoQAQEBAAMBAAAAAAAAAAAAAAERACExUeH/2gAIAQEAAT8QBoCLH43F1uBIVfd//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "EU Kohezijski sklad",
          "title": "EU Kohezijski sklad",
          "src": "/static/c1c91a9ec247d81e9c52edd32a2b8229/f93b5/logocerkv.jpg",
          "srcSet": ["/static/c1c91a9ec247d81e9c52edd32a2b8229/8ddbc/logocerkv.jpg 75w", "/static/c1c91a9ec247d81e9c52edd32a2b8229/75985/logocerkv.jpg 150w", "/static/c1c91a9ec247d81e9c52edd32a2b8229/f93b5/logocerkv.jpg 300w", "/static/c1c91a9ec247d81e9c52edd32a2b8229/20e5d/logocerkv.jpg 450w", "/static/c1c91a9ec247d81e9c52edd32a2b8229/b4294/logocerkv.jpg 600w", "/static/c1c91a9ec247d81e9c52edd32a2b8229/054c0/logocerkv.jpg 633w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h1>{`DOLB Rogašovci`}</h1>
    <h2>{`Projekt vzpostavitve sistema daljinskega ogrevanja na lesno biomaso DOLB Rogašovci je bil izveden leta 2017.`}</h2>
    <p>{`Osnovo projekta DOLB Rogaševci je predstavljala prenova ogrevanja javnih objektov v središču kraja Sv. Jurij, ki je vključevala namestitev novega kotla na lesno biomaso (sekanci) moči 250kW proizvajalca Fröling, poleg tega pa še hranilnik toplote volumna 5.000 litrov, bojler za STV volumna 1500 litrov z ustrezno ekspanzijsko posodo, ustrezna ekspanzijska posoda za ogrevalno vodo, ter ostala potrebna strojna oprema. Obstoječi kotel na kurilno olje se je ohranil kot rezerva za primer težav z delovanjem nove kotlovniške opreme.`}</p>
    <p>{`Skladišče za sekance se je zgradilo kot ločen montažni prizidek in je dimenzije 50 m3.`}</p>
    <p>{`Na prenovljeno kotlovnico se je z novim cevnim razvodom preko toplotnih posta priključilo še kulturni dom in večnamenski objekt v lasti občine.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "initial",
          "marginRight": "initial",
          "maxWidth": "300px",
          "marginBottom": "3rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABgklEQVQoz5WOSyhEcRTG/0PeNsqjeeSaud5v8igSksfVGKykMON8R5iVGpKuR2GU5yivKGuFm0lpNoqNRJKytLW3UqyujLGxwVdf53c2vz5R53FGqpp8P3GY2TZxlNGvavKDqsnJqiYLVZMN4r9ZvUhgb8B4PR9I2Z47M716A8ZL9365cM7aDZOr1QKA4D82mEr30FNq5+xBYt3uVKFr9Cqvb+xUCD0ivWpDrI8qv0rwUxhVtfdmVny6ZF/RLcq6Hluz82JRFi1Pa7bwrr7hGBBFMRDPwOeNYCA8xNFM9PUTic8GE1+79S63L+vJzT49o2NJj6vdfq5wTBe5B5ytRGgC4GDAzkATf3EOgEYGFCZqYCA7tDCMAYMwtS08GltX7qSWzWNji+/K5PDeVvd4pGG4yohQyEAxiEoYsIEol4EyBnJDAvG97pvFtD/tZcqftjZ+bO2ePLHezJxK5yOL9anUO1jKAy4zgKKggCgJRDYGChiQAMgMZDKRxEA+A1kMWD8AT66we26c4+oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "EU Kohezijski sklad",
          "title": "EU Kohezijski sklad",
          "src": "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png",
          "srcSet": ["/static/5e8b160bc594d78f07fd0860209deddf/1db73/logoeu.png 75w", "/static/5e8b160bc594d78f07fd0860209deddf/8a4e8/logoeu.png 150w", "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png 300w", "/static/5e8b160bc594d78f07fd0860209deddf/2a432/logoeu.png 411w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h1>{`DOLB Gornji Petrovci`}</h1>
    <h2>{`Projekt vzpostavitve sistema daljinskega ogrevanja na lesno biomaso DOLB G. Petrovci je bil izveden leta 2018 in je vključeval celovito prenovo ogrevanja osnovne šole, vrtca in večstanovanjskega objekta v Občini Gornji Petrovci.`}</h2>
    <p>{`Osnovo projekta DOLB G. Petrovci je predstavljala namestitev novega kotla na lesno biomaso (sekanci) moči 250kW proizvajalca Fröling v prostorih obstoječe kotlovnice OŠ G. Petrovci. Poleg tega se je v kotlovnico namestil še hranilnik toplote volumna 5.000 litrov, bojler za STV volumna 1500 litrov z ustrezno ekspanzijsko posodo, ustrezna ekspanzijska posoda za ogrevalno vodo, razdelilnik z vsemi potrebnimi črpalkami, ventili, manometri, termometri, itd. Obstoječi kotel na kurilno olje se je ohranil kot rezerva za primer težav z delovanjem nove kotlovniške opreme.`}</p>
    <p>{`Skladišče za sekance dimenzije 50 m3, se je zagotovilo tako, da se je obstoječo kotlovnico v OŠ pregradilo, vsipni jašek za sekance pa se je izvedel v nivoju igrišča, ki se nahaja za objektom OŠ in je eno etažo nad kotlovnico. S tem se je zagotovilo zelo enostaven in učinkovit način polnjenja skladišča sekancev.`}</p>
    <p>{`Na prenovljeno kotlovnico se je z novim cevnim razvodom preko toplotnih posta priključilo še objekt vrtca in večstanovanjski objekt v neposredni bližini, kjer se nahajajo štiri stanovanja v lasti občine in štiri stanovanja v zasebni lasti.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "initial",
          "marginRight": "initial",
          "maxWidth": "300px",
          "marginBottom": "3rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABgklEQVQoz5WOSyhEcRTG/0PeNsqjeeSaud5v8igSksfVGKykMON8R5iVGpKuR2GU5yivKGuFm0lpNoqNRJKytLW3UqyujLGxwVdf53c2vz5R53FGqpp8P3GY2TZxlNGvavKDqsnJqiYLVZMN4r9ZvUhgb8B4PR9I2Z47M716A8ZL9365cM7aDZOr1QKA4D82mEr30FNq5+xBYt3uVKFr9Cqvb+xUCD0ivWpDrI8qv0rwUxhVtfdmVny6ZF/RLcq6Hluz82JRFi1Pa7bwrr7hGBBFMRDPwOeNYCA8xNFM9PUTic8GE1+79S63L+vJzT49o2NJj6vdfq5wTBe5B5ytRGgC4GDAzkATf3EOgEYGFCZqYCA7tDCMAYMwtS08GltX7qSWzWNji+/K5PDeVvd4pGG4yohQyEAxiEoYsIEol4EyBnJDAvG97pvFtD/tZcqftjZ+bO2ePLHezJxK5yOL9anUO1jKAy4zgKKggCgJRDYGChiQAMgMZDKRxEA+A1kMWD8AT66we26c4+oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "EU Kohezijski sklad",
          "title": "EU Kohezijski sklad",
          "src": "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png",
          "srcSet": ["/static/5e8b160bc594d78f07fd0860209deddf/1db73/logoeu.png 75w", "/static/5e8b160bc594d78f07fd0860209deddf/8a4e8/logoeu.png 150w", "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png 300w", "/static/5e8b160bc594d78f07fd0860209deddf/2a432/logoeu.png 411w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h1>{`DOLB Šalovci`}</h1>
    <h2>{`Sistem daljinskega ogrevanja na lesno biomaso DOLB Šalovci je bil zgrajen leta 2019 in predstavlja nadgradnjo obstoječih sistemov ogrevanja več objektov v centru Šalovcev.`}</h2>
    <p>{`Osnovo projekta izgradnje sistema DOLB Šalovci je predstavljala nova kotlovnica, kot prizidek objektu Občinske zgradbe. Gre za sodobno visoko avtomatizirano kotlovnico na lesno biomaso. Pri tem se je na kotlovnico na lesno biomaso preko toplovodnega omrežja priključilo še OŠ z vrtcem, bencinsko črpalko, gostinski objekt in poslovno stanovanjski objekt.`}</p>
    <p>{`Jedro sistema DOLB Šalovci predstavlja Frölingov kotel 250 TM moči 250 kW, poleg kotla pa je bil inštaliran še hranilnik toplote kapacitete 8.000 L.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "initial",
          "marginRight": "initial",
          "maxWidth": "300px",
          "marginBottom": "3rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABgklEQVQoz5WOSyhEcRTG/0PeNsqjeeSaud5v8igSksfVGKykMON8R5iVGpKuR2GU5yivKGuFm0lpNoqNRJKytLW3UqyujLGxwVdf53c2vz5R53FGqpp8P3GY2TZxlNGvavKDqsnJqiYLVZMN4r9ZvUhgb8B4PR9I2Z47M716A8ZL9365cM7aDZOr1QKA4D82mEr30FNq5+xBYt3uVKFr9Cqvb+xUCD0ivWpDrI8qv0rwUxhVtfdmVny6ZF/RLcq6Hluz82JRFi1Pa7bwrr7hGBBFMRDPwOeNYCA8xNFM9PUTic8GE1+79S63L+vJzT49o2NJj6vdfq5wTBe5B5ytRGgC4GDAzkATf3EOgEYGFCZqYCA7tDCMAYMwtS08GltX7qSWzWNji+/K5PDeVvd4pGG4yohQyEAxiEoYsIEol4EyBnJDAvG97pvFtD/tZcqftjZ+bO2ePLHezJxK5yOL9anUO1jKAy4zgKKggCgJRDYGChiQAMgMZDKRxEA+A1kMWD8AT66we26c4+oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "EU Kohezijski sklad",
          "title": "EU Kohezijski sklad",
          "src": "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png",
          "srcSet": ["/static/5e8b160bc594d78f07fd0860209deddf/1db73/logoeu.png 75w", "/static/5e8b160bc594d78f07fd0860209deddf/8a4e8/logoeu.png 150w", "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png 300w", "/static/5e8b160bc594d78f07fd0860209deddf/2a432/logoeu.png 411w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h1>{`DOLB Cankova`}</h1>
    <h2>{`V občini Cankova že vse od leta 2003 deluje sistem daljinskega ogrevanja na lesno biomaso DOLB Cankova. Sistem je bil pred pričetkom projekta prenove neučinkovit in precej dotrajan, ter potreben celovite prenove.`}</h2>
    <p>{`Tako se je v letu 2019 pričelo s pripravami na projekt nadgradnje in širitve obstoječega sistema DOLB Cankova. Za omenjeni projekt je občina podelila koncesijo Sončni zadrugi, pri čemer bo po prenovi Sončna zadruga s sistemom upravljala 25 let.`}</p>
    <p>{`Z izvedbo nadgradnje se je pričelo leta 2020. Ključen del nadgradnje sistema DOLB Cankova predstavlja nova kotlovnica, kjer je inštaliran kotel moči 500 kW s sistemom SPTE tehnologije ORC. Poleg 500kW kotla je za izravnavanje konic postavljen še 10.000L akumulator toplote.`}</p>
    <p>{`Poleg nove kotlovnice ostaja v kotlovnici OŠ v funkciji tudi stari kotel na lesno biomaso KIV moči 830 kW. Kotel bo predstavljal rezervo za primer izpada delovanja novega kotla.`}</p>
    <p>{`V sklopu projekta nadgradnje in širitve obstoječega sistema DOLB Cankova se je širilo tudi toplovodno omrežje. Širitev se je izvedla predvsem na območju, kjer že poteka obstoječa trasa toplovoda. Zaradi pričakovanega povečanega odjema toplotne energije je predvidena nadgradnja toplovoda in toplotnih postaj predvsem na območjih, kjer je prišlo do novih priklopov.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "initial",
          "marginRight": "initial",
          "maxWidth": "300px",
          "marginBottom": "3rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABgklEQVQoz5WOSyhEcRTG/0PeNsqjeeSaud5v8igSksfVGKykMON8R5iVGpKuR2GU5yivKGuFm0lpNoqNRJKytLW3UqyujLGxwVdf53c2vz5R53FGqpp8P3GY2TZxlNGvavKDqsnJqiYLVZMN4r9ZvUhgb8B4PR9I2Z47M716A8ZL9365cM7aDZOr1QKA4D82mEr30FNq5+xBYt3uVKFr9Cqvb+xUCD0ivWpDrI8qv0rwUxhVtfdmVny6ZF/RLcq6Hluz82JRFi1Pa7bwrr7hGBBFMRDPwOeNYCA8xNFM9PUTic8GE1+79S63L+vJzT49o2NJj6vdfq5wTBe5B5ytRGgC4GDAzkATf3EOgEYGFCZqYCA7tDCMAYMwtS08GltX7qSWzWNji+/K5PDeVvd4pGG4yohQyEAxiEoYsIEol4EyBnJDAvG97pvFtD/tZcqftjZ+bO2ePLHezJxK5yOL9anUO1jKAy4zgKKggCgJRDYGChiQAMgMZDKRxEA+A1kMWD8AT66we26c4+oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "EU Kohezijski sklad",
          "title": "EU Kohezijski sklad",
          "src": "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png",
          "srcSet": ["/static/5e8b160bc594d78f07fd0860209deddf/1db73/logoeu.png 75w", "/static/5e8b160bc594d78f07fd0860209deddf/8a4e8/logoeu.png 150w", "/static/5e8b160bc594d78f07fd0860209deddf/5a46d/logoeu.png 300w", "/static/5e8b160bc594d78f07fd0860209deddf/2a432/logoeu.png 411w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      